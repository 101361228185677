<template>
    <b-container v-if="insurance">
        <b-row>
            <b-col cols="4" class="flex-center">
                <h6 class="text-center">
                    Bosna i Hercegovina<br/>
                    FEDERACIJA BiH<br/>
                    Federalno ministarstvo financija/finansija<br/>
                    POREZNA UPRAVA
                </h6>
            </b-col>
            <b-col cols="4" class="flex-center flex-column py-4">
                <h6 class="text-center">Obrazac 2002</h6>
                <h4 class="text-center">Specifikacija uz uplatu doprinosa poduzetnika s prebivalištem u Federaciji BiH</h4>
            </b-col>
            <b-col cols="4" class="flex-center">
                <h6 class="text-center">
                    Za službenu<br/>
                    upotrebu<br/>
                </h6>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col md="12">
                <h5 class="text-center my-4">
                    Dio 1 - Podaci o registriranoj djelatnosti
                </h5>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="5" class="py-4">
                1) Naziv: {{insurance.client.name}}
            </b-col>
            <b-col md="7" class="py-4">
                2) JIB/JMB: {{insurance.client.owner_jmbg}}
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="flex-center">
                <b-form-group
                    class="w-100"
                    label="3) Operacija:">
                    <b-form-radio-group
                        stacked
                        id="operation"
                        name="operation"
                        v-model="insurance.operation">
                        <b-form-radio value="1">Prijava podataka</b-form-radio>
                        <b-form-radio value="2">Izmjena podataka za već predate specifikacije 2002 poslane fondovima</b-form-radio>
                        <b-form-radio value="3">Brisanje podataka za već predate specifikacije 2002 poslane fondovima</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col md="6" class="py-4 flex-center">
                4) Period (od/do) (dan/mjesec/godina):<br/>
                {{insurance.date_from}} <br/>
                {{insurance.date_to}}
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" class="d-flex align-items-center py-4">
                5) Adresa: {{insurance.client.address}}
            </b-col>
            <b-col md="4" class="flex-center">
                6) Općina:
            </b-col>
            <b-col md="4" class="flex-center">
                7)  Broj zaposlenih: {{insurance.employed}}
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="py-4">
                8) Vrsta djelatnosti (šifra i naziv): {{insurance.client.activity.code + ' ' + insurance.client.activity.name}}
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="flex-center">
                <b-form-group
                    class="w-100"
                    label="9) Vrsta samostalne djelatnosti (označiti odgovarajuće polje):">
                    <b-form-radio-group
                        stacked
                        id="activity_type"
                        name="activity_type"
                        v-model="insurance.activity_type">
                        <b-form-radio value="1">a) sloboda zanimanja</b-form-radio>
                        <b-form-radio value="2">b) djelatnost obrta</b-form-radio>
                        <b-form-radio value="3">c) nisko akumulacijska</b-form-radio>
                        <b-form-radio value="4">d) poljoprivrede i šumarstva</b-form-radio>
                        <b-form-radio value="5">e) trgovac pojedinac</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col md="6" class="flex-center">
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                            class="w-100"
                            label="10) Dohodak se utvrđuje na osnovu (oznaćiti odgov. polje):">
                            <b-form-radio-group
                                stacked
                                id="income_type"
                                name="income_type"
                                v-model="insurance.income_type">
                                <b-form-radio value="1">a) poslovnih knjiga</b-form-radio>
                                <b-form-radio value="2">b) paušalna</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="py-4 flex-fill">
                        11)  Osnovica za obračun: {{insurance.base}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" class="py-4">
                12) Broj radnih sati: {{insurance.work_hours}}
            </b-col>
            <b-col md="4" class="py-4">
                13) Broj rad. sati na bolov. : {{insurance.work_hours_bol}}
            </b-col>
            <b-col md="4" class="py-4">
                14) Datum uplate doprinosa (dan/mjesec/godina): {{insurance.pay_day}}
            </b-col>
        </b-row>

        <b-row class="my-4">
            <b-col md="12" class="flex-center">
                <h5 class="text-center my-4">Dio 2 - Podaci o poduzetniku</h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="py-4">
                15) Prezime i ime: {{insurance.client.owner}}
            </b-col>
            <b-col md="6" class="py-4">
                16) JMB: {{insurance.client.owner_jmbg}}
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="py-4">
                17) Adresa: {{insurance.client.address}}
            </b-col>
            <b-col md="6" class="py-4">
                18) Općina:
            </b-col>
        </b-row>
        <b-row class="my-4">
            <b-col md="12" class="flex-center">
                <h5 class="text-center my-4">Dio 3 - Podaci o doprinosima</h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="1" class="flex-center">
                <h5 class="text-center my-4">Red. broj</h5>
            </b-col>
            <b-col md="6" class="flex-center">
                <h5 class="text-center my-4">Opis</h5>
            </b-col>
            <b-col md="2" class="flex-center">
                <h5 class="text-center my-4">Stopa</h5>
            </b-col>
            <b-col md="3" class="flex-center">
                <h5 class="text-center my-4">Iznos</h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="1" class="flex-center">
                <h6 class="text-center my-1">a</h6>
            </b-col>
            <b-col md="6" class="flex-center">
                <h6 class="text-center my-1">b</h6>
            </b-col>
            <b-col md="2" class="flex-center">
                <h6 class="text-center  my-1">c</h6>
            </b-col>
            <b-col md="3" class="flex-center">
                <h6 class="text-center my-1">d</h6>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="1" class="flex-center py-4">
                <h6 class="text-center my-1">19</h6>
            </b-col>
            <b-col md="4" class="flex-center py-4">
                <h6 class="text-center my-1">Doprinos za mirovinsko i zdravstveno osiguranje</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                <h6 class="text-center my-1">712112</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                {{insurance.mir_zdrav}}
            </b-col>
            <b-col md="3" class="flex-center py-4">
                {{insurance.mir_zdrav_izn}}
            </b-col>
        </b-row>

        <b-row>
            <b-col md="1" class="flex-center py-4">
                <h6 class="text-center my-1">20</h6>
            </b-col>
            <b-col md="4" class="flex-center py-4">
                <h6 class="text-center my-1">Doprinos za zdravstveno osiguranje</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                <h6 class="text-center my-1">712111</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                {{insurance.zdrav}}
            </b-col>
            <b-col md="3" class="flex-center py-4">
                {{insurance.zdrav_izn}}
            </b-col>
        </b-row>

        <b-row>
            <b-col md="1" class="flex-center py-4">
                <h6 class="text-center my-1">21</h6>
            </b-col>
            <b-col md="4" class="flex-center py-4">
                <h6 class="text-center my-1">Doprinos za osiguranje od neuposlenosti</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                <h6 class="text-center my-1">712113</h6>
            </b-col>
            <b-col md="2" class="flex-center py-4">
                {{insurance.neup}}
            </b-col>
            <b-col md="3" class="flex-center py-4">
                {{insurance.neup_izn}}
            </b-col>
        </b-row>

        <b-row>
            <b-col md="1" class="flex-center py-4">
                <h6 class="text-center my-1">22</h6>
            </b-col>
            <b-col md="8" class="flex-center py-4">
                <h6 class="text-center my-1">UKUPNO DOPRINOSI (19 + 20 + 21)</h6>
            </b-col>
            <b-col md="3" class="flex-center py-4">
                {{insurance.total}}
            </b-col>
        </b-row>

        <b-row class="my-4">
            <b-col md="12" class="flex-center">
                <h5 class="text-center my-4">Dio 4 - Izjava poduzetnika</h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="flex-center">
                <p class="text-center">Upoznat sam sa svim sankcijama propisanim Zakonom i drugim propisima, izjavljujem da su podaci navedeni u ovoj
                    specifikaciji uključujući sve priloge potpuni, točni i jasni.</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="9">
                <h6 class="my-2">Potpis poreznog obveznika: </h6>
            </b-col>
            <b-col md="3">
                <h6 class="my-2 text-left">Datum: </h6>
            </b-col>
        </b-row>
        <b-row class="no-border my-4 flex-center justify-content-around">
            <router-link :to="$route.path + '/edit'" class="w-25">
                <b-btn variant="primary" class="w-100">
                    Uredi
                </b-btn>
            </router-link>
            <b-btn variant="danger" class="w-25" v-b-modal.deleteInsurance>
                Izbriši
            </b-btn>
        </b-row>
        <b-modal centered
                 id="deleteInsurance"
                 title="Potvrda"
                 @ok="deleteInsurance"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati osiguranje?</p>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                insurance: null
            }
        },
        methods: {
            deleteInsurance() {
                axiOsta.delete(api.insurances + this.insurance.id).
                then(response => {
                    this.$router.replace('/insurances');
                })
            }
        },
        created() {
            axiOsta.get(api.insurances + this.$route.params.id).
            then(response => {
                if (response.status === 200) {
                    this.insurance = response.data;
                }
            });
        }
    }
</script>
